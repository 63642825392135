import React, { useState, useEffect } from "react";
import LoginScreenImage from "../../shared/assets/images/login-screen-image.png";
import Logo from "../../shared/assets/images/logo.png";
import "./home-login.styles.scss";
import { CUSTOM_EVENT_NAMES } from "../../shared/constant/constants";
import { useHistory } from "react-router";
import { useDispatch } from "react-redux";
import { loginUserAsync } from "state/features/authentication/authentication.action";
import { LoginForm } from "./login-form/login-form.component";
import { dispatchCustomEvent } from "shared/methods/utilityFunctions";

export const HomeLogin = () => {
  const [username, setUsername] = useState("");
  const [passwordValue, setPasswordValue] = useState("");
  const [isButtonActive, setButtonActive] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();

  const handleLoginClick = async (event: Event) => {
    event.preventDefault();
    setButtonActive(false);
    dispatch(loginUserAsync({ username: username, password: passwordValue }));
  };

  useEffect(() => {
    dispatchCustomEvent(CUSTOM_EVENT_NAMES.CLEAR_REDUX_DATA);
  }, []);

  return (
    <>
      <div className="loginScreenWrapper">
        <div className="leftLoginBlock">
          <div className="logoHeader">
            <img src={Logo} alt="logo" className="logo" />
          </div>
          <div className="parentContainer">
            <LoginForm
              value={username}
              setValue={setUsername}
              isButtonActive={isButtonActive}
              setButtonActive={setButtonActive}
              onLoginClick={(event: any) => handleLoginClick(event)}
              isLoggedIn={false}
              passwordValue={passwordValue}
              setPasswordValue={setPasswordValue}
              onClickResetPassword={() => {
                history.push(`/reset-password`);
              }}
            />
          </div>
        </div>
        <div className="rightImageBlock">
          <img
            src={LoginScreenImage}
            alt="login screen"
            className="login-screen-image"
          ></img>
          <div className="big-text">
            <div className="line1">Manage Your Patients</div>
            <div className="line2">Effortlessly</div>
          </div>
        </div>
      </div>
    </>
  );
};
