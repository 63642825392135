import { createSlice } from "@reduxjs/toolkit";
import { TOCDetailType } from "pages/toc-detail/toc-detail-types";
import { toast } from "react-toastify";
import { TOAST_MESSAGES } from "shared/constant/constants";
import {
  fetchTransitionOfCareDetailsAsync,
  fetchTransitionOfCareItemsAsync,
  getAllTocFacilitiesAsync,
  approveTocRevisionAsync,
  getPacTypesAsync,
} from "state/features/toc/toc.action";
import { ITocState } from "state/types/toc-slice.type";

const initialState: ITocState = {
  isTocsLoading: false,
  isLoading: false,
  isSilentLoading: false,
  isSearching: false,
  isError: false,
  isValid: false,
  expandedTocIndex: 0,
  suggestions: "",
  counts: {
    pending: 0,
    approved: 0,
  },
  tocs: {
    pending: [],
    approved: [],
  },
  tocDetails: [],
  facilities: [],
  pactTypes: [],
};

const tocSlice = createSlice({
  name: "toc",
  initialState: initialState,
  reducers: {
    setIsLoading: (state: any, action) => {
      state.isLoading = action.payload;
    },
    setIsSearching: (state: any, action) => {
      state.isSearching = action.payload;
    },
    setError: (state: any, action) => {
      state.isError = action.payload;
    },
    setExpandedTocIndex: (state: any, action) => {
      state.expandedTocIndex = action.payload;
    },
    setTocSuggestions: (state: any, action) => {
      state.suggestions = action.payload;
    },
    resetTocs: () => initialState,
    updateTocDetailsList(state, action) {
      state.tocDetails = action.payload;
    },
  },
  extraReducers: (builder) => {
    return (
      builder.addCase(
        fetchTransitionOfCareItemsAsync.pending,
        (state: any, action) => {
          if (action.meta.arg && action.meta.arg.offset > 0) {
            state.isLoading = false;
            state.isSilentLoading = true;
          } else {
            state.isLoading = true;
            state.isSilentLoading = false;
            state.tocs = {
              pending: [],
              approved: [],
            };
            state.counts = {
              pending: 0,
              approved: 0,
            };
          }
          state.isError = false;
        }
      ),
      builder.addCase(
        fetchTransitionOfCareItemsAsync.fulfilled,
        (state: any, action: any) => {
          const counts = action.payload.Count;
          const tocs = action.payload.ToCList;
          state.counts = {
            pending: counts.Pending,
            approved: counts.Approved,
          };
          if (state.isSilentLoading) {
            state.tocs = {
              pending: [...state.tocs.pending, ...tocs.Pending],
              approved: [...state.tocs.approved, ...tocs.Approved],
            };
          } else {
            state.tocs = {
              pending: tocs.Pending,
              approved: tocs.Approved,
            };
          }
          state.isLoading = false;
          state.isSilentLoading = false;
          state.isError = false;
        }
      ),
      builder.addCase(
        fetchTransitionOfCareItemsAsync.rejected,
        (state: any, action) => {
          if (!action.meta.aborted) {
            state.isLoading = false;
            state.isSilentLoading = false;
            toast.error(TOAST_MESSAGES.ERROR, {
              containerId: "main",
              toastId: "toc-error",
            });
          }
        }
      ),
      builder.addCase(
        fetchTransitionOfCareDetailsAsync.pending,
        (state: any, action) => {
          state.isLoading = true;
        }
      ),
      builder.addCase(
        fetchTransitionOfCareDetailsAsync.fulfilled,
        (state: any, action) => {
          state.tocDetails = action.payload.map(
            (item: TOCDetailType, index: number) => {
              if (index === 0) {
                item.isExpanded = true;
              }
              return item;
            }
          );
          state.isLoading = false;
        }
      ),
      builder.addCase(
        fetchTransitionOfCareDetailsAsync.rejected,
        (state: any, action) => {
          state.isLoading = false;
          toast.error(TOAST_MESSAGES.ERROR, {
            containerId: "main",
            toastId: "toc-error",
          });
        }
      ),
      builder.addCase(getAllTocFacilitiesAsync.pending, (state: any) => {
        state.facilities = [];
      }),
      builder.addCase(
        getAllTocFacilitiesAsync.fulfilled,
        (state: any, action) => {
          state.facilities = action.payload;
        }
      ),
      builder.addCase(getAllTocFacilitiesAsync.rejected, (state: any) => {
        state.isError = true;
        state.facilities = [];
      }),
      builder.addCase(approveTocRevisionAsync.rejected, (state: any) => {
        state.isLoading = false;
        state.isError = true;
      }),
      builder.addCase(approveTocRevisionAsync.pending, (state: any) => {
        state.isLoading = true;
        state.isError = false;
      }),
      builder.addCase(
        approveTocRevisionAsync.fulfilled,
        (state: any, action: any) => {
          state.isLoading = false;
          state.isError = !action.payload;
          state.isValid = action.payload;
        }
      ),
      builder.addCase(getPacTypesAsync.fulfilled, (state: any, action: any) => {
        if (action.payload) {
          const pacTypes = action.payload.LocationTypes;
          if (pacTypes && pacTypes.length > 0) {
            state.pacTypes = pacTypes.map((pac: any) => {
              return {
                name: pac.DisplayName,
                id: pac.ID,
              };
            });
          }
        }
      })
    );
  },
});

export default tocSlice.reducer;
export const {
  setIsLoading,
  setIsSearching,
  setError,
  resetTocs,
  setExpandedTocIndex,
  setTocSuggestions,
  updateTocDetailsList,
} = tocSlice.actions;
export const getTocs = (state: any): ITocState => state.toc;
